import defaults from 'lodash/defaults';

import {
  AnnotationEvent,
  DataQueryRequest,
  DataQueryResponse,
  DataSourceApi,
  MetricFindValue,
  DataSourceInstanceSettings,
  ScopedVars,
  TimeRange,
  dateTime,
  MutableDataFrame,
  FieldType,
  DataFrame,
  VariableModel,
  PanelEvents
} from '@grafana/data';

import {
  MyQuery,
  MyDataSourceOptions,
  defaultQuery,
  MyVariableQuery,
  MultiValueVariable,
  TextValuePair,
} from './types';
import { getTemplateSrv, getBackendSrv, locationService } from '@grafana/runtime';
import _ from 'lodash';
import { flatten, isRFC3339_ISO6801 } from './util';
import { QueryField } from '@grafana/ui';


const supportedVariableTypes = ['constant', 'custom', 'query', 'textbox'];

let cache: {[key: string]: {[key: string]: {data: any, exp: number}}}
let cache_options: DataQueryRequest<MyQuery> | undefined    = undefined

interface TemplateVariable extends VariableModel {
    current: {
        selected: boolean,
        text: string,
        value: string
    }
}
export class DataSource extends DataSourceApi<MyQuery, MyDataSourceOptions> {
  basicAuth: string | undefined;
  withCredentials: boolean | undefined;
  url: string | undefined;
  backendSrv: any;

  constructor(instanceSettings: DataSourceInstanceSettings<MyDataSourceOptions>) {
    super(instanceSettings);
    let old_search = window.location.search
    let self = this
    setInterval(async function() {
        if (window.location.search !== old_search) {
            old_search = window.location.search
            if (cache_options !== undefined) {
                // self.query(cache_options);
                cache_options.targets.map((target) => {
                    return self.createQuery(target, defaultQuery, cache_options?.range, cache_options?.scopedVars, cache_options);
                })
            }
        }
    }, 100)
    this.basicAuth          = instanceSettings.basicAuth;
    this.withCredentials    = instanceSettings.withCredentials;
    this.url                = instanceSettings.url;
    cache = {}
  }

  private async request(data: string) {
    const options: any = {
      url: this.url,
      method: 'POST',
      data: {
        query: data,
      },
    };

    if (this.basicAuth || this.withCredentials) {
      options.withCredentials = true;
    }
    if (this.basicAuth) {
      options.headers = {
        Authorization: this.basicAuth,
      };
    }

    return await getBackendSrv().datasourceRequest(options);
  }

  private postQuery(query: Partial<MyQuery>, payload: string) {
    return this.request(payload)
      .then((results: any) => {
        return { query, results };
      })
      .catch((err: any) => {
        if (err.data && err.data.error) {
          throw {
            message: 'GraphQL error: ' + err.data.error.reason,
            error: err.data.error,
          };
        }

        throw err;
      });
  }

  private async createQuery(target: MyQuery, defaultQuery: Partial<MyQuery>, range: TimeRange | undefined, scopedVars: ScopedVars | undefined = undefined, options: DataQueryRequest<MyQuery> | undefined) {
    const query = defaults(target, defaultQuery);
    const { queryText, dataPath, dataLimit, dataPage, dataJwt, cacheLength } = query;
    let _dataPage    = (dataPage === undefined || dataPage === '') ? "0" : dataPage
    if (isNaN(Number(_dataPage))) {
        let pagenum = this.getVar('page')
        if (pagenum?.current?.value !== undefined) {
            _dataPage = String(pagenum.current.value)
        }
    }
    let results = undefined
    let exp = this.getVar('exp')
    this.clearCache(dataPath, _dataPage, cacheLength, exp?.current?.value)
    if (_dataPage !== undefined) {
        if (cache?.[dataPath]?.[_dataPage] !== undefined) {
            results = JSON.parse(JSON.stringify(cache?.[dataPath]?.[_dataPage]?.data))
        }
    }
    let qtext = queryText
    let cache_query = undefined
    
    // if (dataParams !== '') {
    //     if (!queryText.includes("(")) {
    //         qtext = qtext.replace(dataPath, dataPath+"("+dataParams+")")
    //     }
    //     // queryText.replace(dataPath, )
    // }
    // if (dataLimit !== undefined && !isNaN(Number(dataLimit))) {
    //     if (!qtext.includes("l:")) {
    //         qtext = qtext.replace('()', '('+dataParams+', )')
    //     }
    // }
    if (dataLimit !== undefined && !isNaN(Number(dataLimit))) {
        if (!qtext.includes("l:")) {
            qtext = qtext.replace(')', 'l: '+Number(dataLimit)+', )')
        }
    }
    if (dataJwt !== undefined && dataJwt !== '') {
        if (!qtext.includes("jwt:")) {
            qtext = qtext.replace(')', 'jwt: "'+dataJwt+'", )')
        }
    }
    if (_dataPage !== undefined && !isNaN(Number(_dataPage))) {
        if (!qtext.includes("p:")) {
            cache_query = JSON.parse(JSON.stringify(qtext))
            qtext   = qtext.replace(')', 'p: '+ (Number(_dataPage)) +', )')
        }
    }
    if (results === undefined) {
        let payload = this.getPayload(qtext, range, scopedVars)
        if (payload !== undefined) {
            results = await this.postQuery(query, payload);
        } else {
            results = {"error": "Bad Payload"}
        }
    }

    if (_dataPage !== undefined && dataPath !== undefined) {
        this.loadCache(query, cache_query, dataPath, _dataPage, range, scopedVars)
        if (cache?.[dataPath]?.[_dataPage] === undefined) {
            cache[dataPath][_dataPage] = {data: results, exp: new Date().valueOf()}
        }
    }

    let paths = window.location.pathname.split("/");
    if (paths[1] === 'd-solo') {
        let value = JSON.parse(JSON.stringify(results))
        let wrap_results = []
        wrap_results.push(value)

        if (options !== undefined) {
            const customEvent = new CustomEvent('new_data', { detail: this.processDataFrame(wrap_results, options) })
            window.dispatchEvent(customEvent);
        }
    }
    return results
  }
  private clearCache(dataPath: string, dataPage: string, cacheLength: string | undefined, exp: string | undefined) {
    let date_earlier = new Date()
    let cache_timer = 1
    if (cacheLength !== undefined) {
        if (!isNaN(Number(cacheLength))) {
            cache_timer = Number(cacheLength)
        }
    }
    let timer_diff = cache_timer * 60
    date_earlier.setMinutes(date_earlier.getMinutes() - cache_timer);
    let page        = Number(dataPage)
    let prev_page   = page-1
    let next_page   = page+1
    if (cache?.[dataPath]?.[String(page)] !== undefined) {
        let date = cache?.[dataPath]?.[String(page)]?.exp
        let diff = Math.round(date.valueOf()/ 1000) - Math.round(date_earlier.valueOf() /1000)
        let exp_diff    = 0
        if (!isNaN(Number(exp))) {
            exp_diff = Math.round(date.valueOf()/ 1000) - Math.round(Number(exp).valueOf() /1000)
        }
        if (diff <= 0 || exp_diff < 0) {
            delete cache?.[dataPath]?.[String(page)]
        }
    }
    if (cache?.[dataPath]?.[String(prev_page)] !== undefined) {
        let date = cache?.[dataPath]?.[String(prev_page)]?.exp
        let diff = Math.round(date.valueOf()/ 1000) - Math.round(date_earlier.valueOf() /1000)
        let exp_diff    = 0
        if (!isNaN(Number(exp))) {
            exp_diff = Math.round(date.valueOf()/ 1000) - Math.round(Number(exp).valueOf() /1000)
        }
        if (diff <= 0 || exp_diff < 0) {
            delete cache?.[dataPath]?.[String(prev_page)]
        }
    }
    if (cache?.[dataPath]?.[String(next_page)] !== undefined) {
        let date = cache?.[dataPath]?.[String(next_page)]?.exp
        let diff = Math.round(date.valueOf()/ 1000) - Math.round(date_earlier.valueOf() /1000)
        let exp_diff    = 0
        if (!isNaN(Number(exp))) {
            exp_diff = Math.round(date.valueOf()/ 1000) - Math.round(Number(exp).valueOf() /1000)
        }
        if (diff <= 0 || exp_diff < 0) {
            delete cache?.[dataPath]?.[String(next_page)]
        }
    }
  }
  
  private async loadCache(query: Partial<MyQuery>, qtext: string, dataPath: string, dataPage: string, range: TimeRange | undefined, scopedVars: ScopedVars | undefined = undefined) {
    if (dataPath !== undefined) {
        let page        = Number(dataPage)
        let prev_page   = page-1
        let next_page   = page+1
        if (cache?.[dataPath] === undefined) {
            cache[dataPath] = {}
        }
        // REFACTOR: Change to time based expiry; page based deletion will result in multiple users clearing caches to rapidly to be useful.
        // delete cache[String(prev_page-1)]
        // delete cache[String(next_page+1)]
        if (cache?.[dataPath]?.[String(next_page)] === undefined && qtext !== undefined) {
            let qtext_next = qtext
            if (!qtext.includes("p:")) {
                qtext_next   = qtext.replace(')', ', p: '+ (Number(next_page)) +')')
            }
            let payload_next = await this.getPayload(qtext_next, range, scopedVars)
            if (payload_next !== undefined) {
                cache[dataPath][String(next_page)] = {data: (await this.postQuery(query, payload_next)), exp: new Date().valueOf()}
            }
        }
        if (prev_page >= 0) {
            if (cache?.[dataPath]?.[String(prev_page)] === undefined) {
                let qtext_next = qtext
                if (!qtext.includes("p:")) {
                    qtext_next   = qtext.replace(')', ', p: '+ (Number(prev_page)) +')')
                }
                let payload_prev = this.getPayload(qtext_next, range, scopedVars)
                if (payload_prev !== undefined) {
                    cache[dataPath][String(prev_page)] = {data: (await this.postQuery(query, payload_prev)), exp: new Date().valueOf()}
                }
            }
        }
    }
  }
  private getPayload(query: string | undefined, range: TimeRange | undefined, scopedVars: ScopedVars | undefined = undefined) {
    if (query !== undefined) {
        let payload = getTemplateSrv().replace(query, {
            ...scopedVars,
            timeFrom: { text: 'from', value: range?.from.valueOf() },
            timeTo: { text: 'to', value: range?.to.valueOf() },
        });
        return payload
    }
    return ""
  }
  private getVar(varname: string): TemplateVariable | undefined {
    let selected = undefined
    if (varname !== '') {
        let vars = getTemplateSrv().getVariables()
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let new_var   = urlParams.get("var-"+varname)

        let found_var = vars.find((v) => v.name === varname) as TemplateVariable
        if (found_var !== undefined) {
            selected = JSON.parse(JSON.stringify(vars.find((v) => v.name === varname) as TemplateVariable))
        }
        if (selected?.current?.value !== new_var) {
            if (new_var !== null && new_var !== undefined) {
                this.updateGrafanaValue(varname, String(new_var))
                selected.current.value = String(new_var)
            }
        }
        return selected
    }
    return
  }
  private updateGrafanaValue(variableName: String, value: Number | String) {
    locationService.partial(
        {
            [`var-${variableName}`]: value,
        },
        true // replace: true tells Grafana to update the current URL state, rather than creating a new history entry.
    );
 }
 
  private static getDocs(resultsData: any, dataPath: string): any[] {
    if (!resultsData) {
      throw 'resultsData was null or undefined';
    }
    let data = dataPath.split('.').reduce((d: any, p: any) => {
      if (!d) {
        return null;
      }
      return d[p];
    }, resultsData.data);
    if (!data) {
      const errors: any[] = resultsData.errors;
      if (errors && errors.length !== 0) {
        throw errors[0];
      }
      throw 'Your data path did not exist! dataPath: ' + dataPath;
    }
    if (resultsData.errors) {
      // There can still be errors even if there is data
      console.log('Got GraphQL errors:');
      console.log(resultsData.errors);
    }
    const docs: any[] = [];
    let pushDoc = (originalDoc: object) => {
      docs.push(flatten(originalDoc));
    };
    if (Array.isArray(data)) {
      for (const element of data) {
        pushDoc(element);
      }
    } else {
      pushDoc(data);
    }
    return docs;
  }
  private static getDataPathArray(dataPathString: string): string[] {
    const dataPathArray: string[] = [];
    for (const dataPath of dataPathString.split(',')) {
      const trimmed = dataPath.trim();
      if (trimmed) {
        dataPathArray.push(trimmed);
      }
    }
    if (!dataPathArray) {
      throw 'data path is empty!';
    }
    return dataPathArray;
  }

  async query(options: DataQueryRequest<MyQuery>): Promise<DataQueryResponse> {
    cache_options = options
    return Promise.all(
      options.targets.map((target) => {
        return this.createQuery(target, defaultQuery, options.range, options.scopedVars, options);
      })
    ).then((results: any) => {
      return this.processDataFrame(results, options)
    });
  }
  processDataFrame(results: any, options: DataQueryRequest<MyQuery>) {
    const dataFrameArray: DataFrame[] = [];
      for (let res of results) {
        const dataPathArray: string[] = DataSource.getDataPathArray(res.query.dataPath);
        const { timePath, timeFormat, groupBy, aliasBy } = res.query;
        const split = groupBy.split(',');
        const groupByList: string[] = [];
        for (const element of split) {
          const trimmed = element.trim();
          if (trimmed) {
            groupByList.push(trimmed);
          }
        }
        for (const dataPath of dataPathArray) {
          const docs: any[] = DataSource.getDocs(res.results.data, dataPath);

          const dataFrameMap = new Map<string, MutableDataFrame>();
          for (const doc of docs) {
            if (timePath in doc) {
              doc[timePath] = dateTime(doc[timePath], timeFormat);
            }
            const identifiers: string[] = [];
            for (const groupByElement of groupByList) {
              identifiers.push(doc[groupByElement]);
            }
            const identifiersString = identifiers.toString();
            let dataFrame = dataFrameMap.get(identifiersString);
            if (!dataFrame) {
              // we haven't initialized the dataFrame for this specific identifier that we group by yet
              dataFrame = new MutableDataFrame({ fields: [] });
              const generalReplaceObject: any = {};
              for (const fieldName in doc) {
                generalReplaceObject['field_' + fieldName] = doc[fieldName];
              }
              for (const fieldName in doc) {
                let t: FieldType = FieldType.string;
                if (fieldName === timePath || isRFC3339_ISO6801(String(doc[fieldName]))) {
                  t = FieldType.time;
                } else if (_.isNumber(doc[fieldName])) {
                  t = FieldType.number;
                }
                let title;
                if (identifiers.length !== 0) {
                  // if we have any identifiers
                  title = identifiersString + '_' + fieldName;
                } else {
                  title = fieldName;
                }
                if (aliasBy) {
                  title = aliasBy;
                  const replaceObject = { ...generalReplaceObject };
                  replaceObject['fieldName'] = fieldName;
                  for (const replaceKey in replaceObject) {
                    const replaceValue = replaceObject[replaceKey];
                    const regex = new RegExp('\\$' + replaceKey, 'g');
                    title = title.replace(regex, replaceValue);
                  }
                  title = getTemplateSrv().replace(title, options.scopedVars);
                }
                dataFrame.addField({
                  name: fieldName,
                  type: t,
                  config: { displayName: title },
                }).parse = (v: any) => {
                    return v || '';
                  };
              }
              dataFrameMap.set(identifiersString, dataFrame);
            }

            dataFrame.add(doc);
          }
          for (const dataFrame of dataFrameMap.values()) {
            dataFrameArray.push(dataFrame);
          }
        }
      }
      return { data: dataFrameArray };
  }
  annotationQuery(options: any): Promise<AnnotationEvent[]> {
    const query = defaults(options.annotation, defaultQuery);
    return Promise.all([this.createQuery(query, defaultQuery, options.range, options.scopedVars, options)]).then((results: any) => {
      const r: AnnotationEvent[] = [];
      for (const res of results) {
        const { timePath, endTimePath, timeFormat } = res.query;
        const dataPathArray: string[] = DataSource.getDataPathArray(res.query.dataPath);
        for (const dataPath of dataPathArray) {
          const docs: any[] = DataSource.getDocs(res.results.data, dataPath);
          for (const doc of docs) {
            const annotation: AnnotationEvent = {};
            if (timePath in doc) {
              annotation.time = dateTime(doc[timePath], timeFormat).valueOf();
            }
            if (endTimePath in doc) {
              annotation.isRegion = true;
              annotation.timeEnd = dateTime(doc[endTimePath], timeFormat).valueOf();
            }
            let title = query.annotationTitle;
            let text = query.annotationText;
            let tags = query.annotationTags;
            for (const fieldName in doc) {
              const fieldValue = doc[fieldName];
              const replaceKey = 'field_' + fieldName;
              const regex = new RegExp('\\$' + replaceKey, 'g');
              title = title.replace(regex, fieldValue);
              text = text.replace(regex, fieldValue);
              tags = tags.replace(regex, fieldValue);
            }

            annotation.title = title;
            annotation.text = text;
            const tagsList: string[] = [];
            for (const element of tags.split(',')) {
              const trimmed = element.trim();
              if (trimmed) {
                tagsList.push(trimmed);
              }
            }
            annotation.tags = tagsList;
            r.push(annotation);
          }
        }
      }
      return r;
    });
  }

  testDatasource() {
    const q = `{
      __schema{
        queryType{name}
      }
    }`;
    return this.postQuery(defaultQuery, q).then(
      (res: any) => {
        if (res.errors) {
          console.log(res.errors);
          return {
            status: 'error',
            message: 'GraphQL Error: ' + res.errors[0].message,
          };
        }
        return {
          status: 'success',
          message: 'Success',
        };
      },
      (err: any) => {
        console.log(err);
        return {
          status: 'error',
          message: 'HTTP Response ' + err.status + ': ' + err.statusText,
        };
      }
    );
  }

  async metricFindQuery(query: MyVariableQuery, options?: any) {
    const metricFindValues: MetricFindValue[] = [];

    query = defaults(query, defaultQuery);

    let payload = query.queryText;
    payload = getTemplateSrv().replace(payload, { ...this.getVariables });
    if (payload !== undefined) {
        const response = await this.postQuery(query, payload);

        const docs: any[] = DataSource.getDocs(response.results.data, query.dataPath);

        for (const doc of docs) {
        if ('__text' in doc && '__value' in doc) {
            metricFindValues.push({ text: doc['__text'], value: doc['__value'] });
        } else {
            for (const fieldName in doc) {
            metricFindValues.push({ text: doc[fieldName] });
            }
        }
        }
    }
    return metricFindValues;
  }

  getVariables() {
    const variables: { [id: string]: TextValuePair } = {};
    Object.values(getTemplateSrv().getVariables()).forEach((variable) => {
      if (!supportedVariableTypes.includes(variable.type)) {
        console.warn(`Variable of type "${variable.type}" is not supported`);

        return;
      }

      const supportedVariable = variable as MultiValueVariable;

      let variableValue = supportedVariable.current.value;
      if (variableValue === '$__all' || _.isEqual(variableValue, ['$__all'])) {
        if (supportedVariable.allValue === null || supportedVariable.allValue === '') {
          variableValue = supportedVariable.options.slice(1).map((textValuePair) => textValuePair.value);
        } else {
          variableValue = supportedVariable.allValue;
        }
      }

      variables[supportedVariable.id] = {
        text: supportedVariable.current.text,
        value: variableValue,
      };
    });

    return variables;
  }
}
